import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AppDataContext } from "../contexts/AppData.context";
import { useNavigate } from "react-router-dom";

import styles from "../styles/Stepper.module.scss";

const Stepper = () => {
  const { t } = useTranslation();

  const { step, isMobile, maxStep } = useContext(AppDataContext);

  const navigate = useNavigate();

  const isActive = (step, index) => {
    if (isMobile) {
      return step === index;
    } else {
      return step >= index;
    }
  };

  const stepMap = {
    "/": 1,
    "/sqft": 2,
    "/services": 3,
    "/checkout": 4,
    "/success": 5,
  };

  const handleClick = (url) => {
    if (stepMap[url] > maxStep) return;

    navigate(url);
  };

  return (
    step < 5 && (
      <div
        className={styles.stepper}
        style={{
          borderTop: isMobile ? "1px solid #E0E0E0" : "none",
          paddingTop: isMobile ? "16px" : "0",
        }}
      >
        <span
          className={styles.stepper__line}
          style={{ top: isMobile ? "35px" : "21px" }}
        />
        <div className={styles.container} onClick={() => handleClick("/")}>
          <span
            className={`${styles.step} ${
              isActive(step, 1) ? styles.active : ""
            }`}
          >
            1
          </span>
          <span
            className={`${styles.container__text} ${
              step < 1 ? styles["container__text--inActive"] : ""
            }`}
          >
            {t("Your Address")}
          </span>
        </div>

        <div className={styles.container} onClick={() => handleClick("/sqft")}>
          <span
            className={`${styles.step} ${
              isActive(step, 2) ? styles.active : ""
            }`}
          >
            2
          </span>
          <span
            className={`${styles.container__text} ${
              step < 2 ? styles["container__text--inActive"] : ""
            }`}
          >
            {t("Your Square Footage")}
          </span>
        </div>

        <div
          className={styles.container}
          onClick={() => handleClick("/services")}
        >
          <span
            className={`${styles.step} ${
              isActive(step, 3) ? styles.active : ""
            }`}
          >
            3
          </span>
          <span
            className={`${styles.container__text} ${
              step < 3 ? styles["container__text--inActive"] : ""
            }`}
          >
            {t("Choose Service")}
          </span>
        </div>
        <div
          className={styles.container}
          onClick={() => handleClick("/checkout")}
        >
          <span
            className={`${styles.step} ${
              isActive(step, 4) ? styles.active : ""
            }`}
          >
            4
          </span>
          <span
            className={`${styles.container__text} ${
              step < 4 ? styles["container__text--inActive"] : ""
            }`}
          >
            {t("Checkout")}
          </span>
        </div>
      </div>
    )
  );
};

export default Stepper;
