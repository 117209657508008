import React, { useContext } from "react";

import { AppDataContext } from "../contexts/AppData.context";
import { getServiceCost } from "../utils/priceCalculator";
import { useTranslation } from "react-i18next";

import styles from "../styles/SelectedServices.module.scss";

const PRICING = [
  {
    name: "Subtotal",
    formula: (x) => x.toFixed(2),
  },
  { name: "GST", formula: (x) => (x * 0.05).toFixed(2) },
  { name: "QST", formula: (x) => (x * 0.09975).toFixed(2) },
  { name: "Total", formula: (x) => (x * 1.14975).toFixed(2) },
  {
    name: "3-Year Contract Total",
    formula: (x) => (x * 1.149753 * 3).toFixed(2),
  },
];

const SelectedServices = () => {
  const {
    area,
    servicesCost,
    selectedServices,
    couponWasApplied,
    selectedSchedule,
    coreSoilVisits,
  } = useContext(AppDataContext);
  const { t } = useTranslation();

  const isCore = (service) => service.includes("Core Soil Aeration");

  const filteredPricing = selectedSchedule?.includes("3 Year")
    ? PRICING
    : PRICING.filter((item) => item.name !== "3-Year Contract Total");

  const getDiscount = () => {
    var discount = 0;
    if (selectedSchedule?.includes("Annual")) discount += 5;
    if (couponWasApplied) discount += 20;
    return discount;
  };

  const totalDiscount = (100 - getDiscount()) / 100;

  return (
    <>
      <div className={styles.selectedServices}>
        <span className={styles.ss__header}>{t("Selected Services :")}</span>
        <span className={styles.ss__num}>{selectedServices.length}</span>
      </div>

      {selectedServices.map((service, index) => (
        <div className={styles.service} key={`${service}-price-${index}`}>
          <span className={`${styles.service__name} ${styles.bullet}`}>
            {t(service)}
            {service === "The Essential Plan"
              ? ` (${t("4 visits at")} ${(
                  getServiceCost("The Essential Plan", area) / 4
                ).toFixed(2)}$/${t("visit")})`
              : ""}
          </span>
          <span className={styles.dashLine} />
          <span className={styles.service__name}>
            {totalDiscount !== 1 && (
              <span className={styles.discountPriceText}>
                {isCore(service)
                  ? (
                      getServiceCost(service, area) * coreSoilVisits.length
                    ).toFixed(2)
                  : getServiceCost(service, area).toFixed(2)}{" "}
                $
              </span>
            )}{" "}
            {isCore(service)
              ? (
                  getServiceCost(service, area) *
                  totalDiscount *
                  coreSoilVisits.length
                ).toFixed(2)
              : (getServiceCost(service, area) * totalDiscount).toFixed(2)}{" "}
            $
          </span>
        </div>
      ))}

      <span className={styles.greenDivider} />

      {filteredPricing.map((item) => (
        <div className={styles.service} key={`${item.name}-price`}>
          <span
            className={`${styles.service__name} ${
              !item.name?.includes("Total") ? styles["service__name--gray"] : ""
            }`}
          >
            {t(item.name)}
          </span>
          <span className={styles.dashLine} />
          <span
            className={`${styles.service__name} ${
              !item.name?.includes("Total") ? styles["service__name--gray"] : ""
            }`}
          >
            {selectedSchedule?.includes("Annual")
              ? couponWasApplied
                ? item.formula(servicesCost * 0.75)
                : item.formula(servicesCost * 0.95)
              : couponWasApplied
              ? item.formula(servicesCost * 0.8)
              : item.formula(servicesCost)}{" "}
            {item.name === "Total" && selectedSchedule?.includes("Annual")
              ? t("$/year")
              : "$"}
          </span>
        </div>
      ))}
    </>
  );
};

export default SelectedServices;
