import styles from "../styles/AddressPage.module.scss";
import { useContext } from "react";
import { AppDataContext } from "../contexts/AppData.context";
import AreaPanel from "../components/AreaPanel";
import Map, { Marker } from "react-map-gl";
import DrawControl from "../components/DrawControl";
import { useTranslation } from "react-i18next";
import { useState, useCallback, useRef } from "react";
import { SearchBox } from "@mapbox/search-js-react";
import ALERT_ICON from "../assets/alert.png";
import GIF_URL from "../assets/calculator-gif.mp4";
import MapboxDraw from "@mapbox/mapbox-gl-draw";

const searchBoxTheme = {
  variables: {
    boxShadow: "none",
    borderRadius: "8px",
    background: "#fff",
    border: "1px solid #65AE6B",
  },
  icons: {
    search: "",
  },
};

const TraceLawn = ({
  handleGetOnlineQuote,
  setIsTraceLawn,
  setUserHasSelectedLawnSize,
  updateLawnSize,
}) => {
  const {
    long,
    lat,
    isMobile,
    setLong,
    setLat,
    selectedAddress,
    setSelectedAddress,
  } = useContext(AppDataContext);
  const { t } = useTranslation();
  const [manualAreaEntry, setManualAreaEntry] = useState(false);
  const [features, setFeatures] = useState({});

  const onDelete = useCallback((e) => {
    if (e.features?.length > 0) {
      setFeatures((currFeatures) => {
        const newFeatures = { ...currFeatures };
        for (const f of e.features) {
          delete newFeatures[f.id];
        }
        return newFeatures;
      });
    }
  }, []);

  const onUpdate = useCallback(
    (e) => {
      setManualAreaEntry(false);
      setUserHasSelectedLawnSize(true);
      setFeatures((currFeatures) => {
        const newFeatures = { ...currFeatures };
        for (const f of e.features) {
          newFeatures[f.id] = f;
        }
        return newFeatures;
      });
    },
    [setUserHasSelectedLawnSize]
  );

  const clearMap = () => {
    console.log("clearing");
    console.log(features);

    // Clear features from the state
    setFeatures({});

    // Access the DrawControl instance and clear all features
    const map = mapRef.current.getMap();
    const drawControl = map._controls.find(
      (control) => control instanceof MapboxDraw
    );
    if (drawControl) {
      drawControl.deleteAll();
    }
  };

  const [viewState, setViewState] = useState({
    latitude: lat,
    longitude: long,
    zoom: 17,
  });

  const mapRef = useRef(null);

  const handleRetrieve = (result) => {
    const { features } = result;
    const { geometry, properties } = features[0];
    const { coordinates } = geometry;

    const { full_address: fullAddress } = properties;
    const [long, lat] = coordinates;

    setLong(long);
    setLat(lat);
    setSelectedAddress(fullAddress);
    setViewState({
      latitude: lat,
      longitude: long,
      zoom: 16,
    });
  };

  return (
    <div className={styles.traceLawn}>
      <button
        className={styles.traceLawn__closeButton}
        onClick={() => setIsTraceLawn(false)}
      >
        <span style={{ fontSize: "1.25rem" }}>&times;</span>
      </button>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "2rem",
          justifyContent: "space-between",
          marginTop: "2rem",
        }}
      >
        {!isMobile && (
          <section className={styles.infoSection} style={{ height: "100%" }}>
            <div className={styles.infoSection__header}>
              <div className={styles.freeQuote}>{t("FREE QUOTE")}</div>
              <h1 style={{ textTransform: "capitalize" }}>
                {t("Trace Your Lawn To Find Out Your Sft")}
              </h1>
            </div>
            <div className={styles.alert}>
              <img
                src={ALERT_ICON}
                alt="alert icon"
                className={styles.alert__icon}
              />
              <p className={styles.alert__text}>{t("ALERT_NOTE")}</p>
            </div>
            <form className={styles.form}>
              <SearchBox
                accessToken={process.env.REACT_APP_MAPBOX_KEY}
                value={selectedAddress}
                onChange={(newAddress) => setSelectedAddress(newAddress)}
                map={mapRef.current}
                onRetrieve={handleRetrieve}
                theme={searchBoxTheme}
                placeholder={t("Enter your address")}
              />
            </form>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <video
                src={GIF_URL}
                alt="trace lawn"
                loop
                autoPlay
                muted
                style={{ width: "100%", height: "100%", borderRadius: "12px" }}
              />
            </div>
          </section>
        )}

        {!isMobile && (
          <section className={styles.mapSection}>
            {!isMobile && (
              <AreaPanel
                polygons={Object.values(features)}
                manualAreaEntry={manualAreaEntry}
                updateLawnSize={updateLawnSize}
              />
            )}
            <div className={styles.mapContainer}>
              <Map
                {...viewState}
                mapStyle="mapbox://styles/mapbox/satellite-v9"
                mapboxAccessToken={process.env.REACT_APP_MAPBOX_KEY}
                ref={mapRef}
                onMove={(evt) => setViewState(evt.viewState)}
                height="100%"
                style={{ borderRadius: "12px" }}
                interactive={true}
              >
                {isMobile && (
                  <AreaPanel
                    polygons={Object.values(features)}
                    manualAreaEntry={manualAreaEntry}
                  />
                )}
                {long !== 0 && lat !== 0 && (
                  <Marker longitude={long} latitude={lat} color="red" />
                )}{" "}
                <DrawControl
                  position="top-left"
                  displayControlsDefault={false}
                  controls={{
                    polygon: true,
                    trash: true,
                  }}
                  defaultMode="draw_polygon"
                  onCreate={onUpdate}
                  onUpdate={onUpdate}
                  onDelete={onDelete}
                />
              </Map>
            </div>
          </section>
        )}
      </div>
      <div className={styles.traceLawnButtonsContainer}>
        <button className={styles.traceLawn__clearButton} onClick={clearMap}>
          {t("Clear Map")}
        </button>
        <button
          className={styles.traceLawn__saveButton}
          onClick={handleGetOnlineQuote}
        >
          {t("Save")}
        </button>
      </div>
    </div>
  );
};

export default TraceLawn;
