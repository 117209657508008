import React, { useState, useEffect, useContext, useRef } from "react";
import { AppDataContext } from "../contexts/AppData.context";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

import SelectedServices from "../components/SelectedServices";
import PaymentSchedule from "../components/PaymentSchedule";
import MenuBar from "../components/MenuBar";
import Modal from "../components/Modal";
import ScrollToTop from "../components/ScrollToTop";

import { SearchBox } from "@mapbox/search-js-react";

import { createCustomerAndContract, getCities } from "../middleware/api";

import useDetectPageLeave from "../hooks/useDetectPageLeave";

import styles from "../styles/CheckoutPage.module.scss";

const PROMO_CODES = ["PROMO20", "D2D", "EARLYBIRD", "LEVE-TOT"];

const searchBoxTheme = {
  variables: {
    boxShadow: "none",
    borderRadius: "8px",
    background: "#fff",
  },
  icons: {
    search: "",
  },
};

const CardImages = () => {
  return (
    <div className={styles.inputImages}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="16"
        viewBox="0 0 24 16"
        fill="none"
      >
        <path
          d="M21.75 0.25H2.25C1.14543 0.25 0.25 1.14543 0.25 2.25V13.75C0.25 14.8546 1.14543 15.75 2.25 15.75H21.75C22.8546 15.75 23.75 14.8546 23.75 13.75V2.25C23.75 1.14543 22.8546 0.25 21.75 0.25Z"
          fill="white"
          stroke="black"
          strokeOpacity="0.2"
          strokeWidth="0.5"
        />
        <path
          d="M2.78773 5.91444C2.26459 5.62751 1.66754 5.39674 1 5.23659L1.028 5.11188H3.76498C4.13596 5.12489 4.43699 5.23651 4.53495 5.63071L5.12977 8.46659L5.31198 9.32073L6.97797 5.11188H8.77679L6.10288 11.2775H4.30397L2.78773 5.91444ZM10.1 11.2841H8.39883L9.46285 5.11188H11.1639L10.1 11.2841ZM16.2668 5.26277L16.0354 6.59559L15.8816 6.53004C15.5737 6.40525 15.1674 6.28054 14.6144 6.29371C13.9427 6.29371 13.6415 6.56277 13.6345 6.82546C13.6345 7.11441 13.9989 7.30484 14.5939 7.58725C15.574 8.02719 16.0286 8.56557 16.0218 9.26819C16.0081 10.5486 14.846 11.3761 13.0611 11.3761C12.2979 11.3694 11.5628 11.2181 11.1638 11.0476L11.4019 9.66205L11.6259 9.76066C12.1789 9.99071 12.5428 10.089 13.222 10.089C13.7118 10.089 14.2369 9.89838 14.2436 9.48488C14.2436 9.21565 14.0199 9.01851 13.3617 8.71646C12.7178 8.42087 11.8568 7.92848 11.8708 7.04198C11.8781 5.84042 13.0611 5 14.741 5C15.399 5 15.9312 5.13789 16.2668 5.26277ZM18.5278 9.09749H19.9417C19.8718 8.78889 19.5496 7.31147 19.5496 7.31147L19.4307 6.77964C19.3467 7.00943 19.1999 7.38373 19.2069 7.37056C19.2069 7.37056 18.6678 8.7429 18.5278 9.09749ZM20.6276 5.11188L22 11.284H20.4249C20.4249 11.284 20.2708 10.5748 20.2219 10.3581H18.0378C17.9746 10.5222 17.6808 11.284 17.6808 11.284H15.8958L18.4226 5.62399C18.5977 5.22342 18.906 5.11188 19.3118 5.11188H20.6276Z"
          fill="#171E6C"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="16"
        viewBox="0 0 24 16"
        fill="none"
      >
        <path
          d="M22 0H2C0.89543 0 0 0.89543 0 2V14C0 15.1046 0.89543 16 2 16H22C23.1046 16 24 15.1046 24 14V2C24 0.89543 23.1046 0 22 0Z"
          fill="#252525"
        />
        <path
          d="M9 13C11.7614 13 14 10.7614 14 8C14 5.23858 11.7614 3 9 3C6.23858 3 4 5.23858 4 8C4 10.7614 6.23858 13 9 13Z"
          fill="#EB001B"
        />
        <path
          d="M15 13C17.7614 13 20 10.7614 20 8C20 5.23858 17.7614 3 15 3C12.2386 3 10 5.23858 10 8C10 10.7614 12.2386 13 15 13Z"
          fill="#F79E1B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 3.99951C13.2144 4.91172 14 6.36406 14 7.99988C14 9.6357 13.2144 11.088 12 12.0002C10.7856 11.088 10 9.6357 10 7.99988C10 6.36406 10.7856 4.91172 12 3.99951Z"
          fill="#FF5F00"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="16"
        viewBox="0 0 24 16"
        fill="none"
      >
        <path
          d="M22 0H2C0.89543 0 0 0.89543 0 2V14C0 15.1046 0.89543 16 2 16H22C23.1046 16 24 15.1046 24 14V2C24 0.89543 23.1046 0 22 0Z"
          fill="#016FD0"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.7642 13.3938V7.69238L23.9117 7.70149V9.27638L22.7388 10.5298L23.9117 11.7947V13.403H22.0391L21.0439 12.3048L20.0558 13.4071L13.7642 13.3938Z"
          fill="#FFFFFE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.4419 12.7687V8.31982H18.2142V9.34471H15.6633V10.0404H18.1534V11.0482H15.6633V11.7315H18.2142V12.7687H14.4419Z"
          fill="#016FD0"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.1952 12.7689L20.2825 10.5419L18.1951 8.32007H19.8107L21.0862 9.7301L22.3653 8.32007H23.9115V8.35507L21.8686 10.5419L23.9115 12.7057V12.7689H22.3498L21.0516 11.3447L19.7669 12.7689H18.1952Z"
          fill="#016FD0"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.2374 2.63184H16.6834L17.5426 4.58269V2.63184H20.5624L21.0832 4.09341L21.6057 2.63184H23.9116V8.33323H11.7251L14.2374 2.63184Z"
          fill="#FFFFFE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.7006 3.25146L12.7266 7.69664H14.0805L14.4529 6.80647H16.4708L16.843 7.69664H18.2306L16.2648 3.25146H14.7006ZM14.8702 5.8089L15.4622 4.39383L16.0538 5.8089H14.8702Z"
          fill="#016FD0"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.2122 7.69582V3.25073L20.1153 3.25727L21.0945 5.99L22.0802 3.25073H23.9118V7.69582L22.7332 7.70625V4.6529L21.6206 7.69582H20.5448L19.4092 4.64247V7.69582H18.2122Z"
          fill="#016FD0"
        />
      </svg>
    </div>
  );
};

const CheckoutPage = () => {
  const [showSpecialInstructions, setShowSpecialInstructions] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const outletRef = useRef(null);

  const {
    selectedSchedule,
    setSelectedSchedule,
    servicesCost,
    selectedServices,
    selectedAddress,
    name,
    phone,
    email,
    setName,
    setPhone,
    setEmail,
    companyName,
    setCompanyName,
    specialInstructions,
    setSpecialInstructions,
    isPromoDisabled,
    setIsPromoDisabled,
    promoCode,
    setPromoCode,
    setStep,
    isMobile,
    coreSoilVisits,
    cardNumber,
    setCardNumber,
    cardHolder,
    setCardHolder,
    cardExpiration,
    setCardExpiration,
    cardCVC,
    setCardCVC,
    area,
    cardType,
    setCardType,
    setMaxStep,
    selectedBillingAddress,
    setSelectedBillingAddress,
    billingAddressObj,
    setBillingAddressObj,
    addressObj,
    setCouponWasApplied,
    couponWasApplied,
    contactWasSentToGHL,
    setContactWasSentToGHL,
  } = useContext(AppDataContext);

  const navigate = useNavigate();

  const { t } = useTranslation();

  const checkPromoCode = (e) => {
    e.preventDefault();

    if (PROMO_CODES.includes(promoCode)) {
      setIsPromoDisabled(true);
      setCouponWasApplied(true);
      toast.success("Promo code applied!");
    } else {
      toast.error("Promo code not found!");
    }
  };

  const handleBackClick = () => {
    navigate("/services");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const requiredFields = [
        selectedSchedule,
        servicesCost,
        selectedServices,
        selectedAddress,
        name,
        phone,
        email,
        cardNumber,
        cardHolder,
        cardExpiration,
        cardCVC,
        area,
        selectedBillingAddress,
      ];

      if (requiredFields.some((field) => !field)) {
        toast.error(
          t("Please make sure that you have completed all the fields.")
        );
        return;
      }

      const addressObjValues = Object.values(addressObj);
      const billingAddressObjValues = Object.values(billingAddressObj);

      if (addressObjValues.includes(null)) {
        toast.error(
          t(
            "There was a problem with your address. Please go back to step 1 and try again."
          )
        );
        return;
      }

      if (billingAddressObjValues.includes(null)) {
        toast.error(
          t(
            "There was a problem with your billing address. Please select it again from the dropdown."
          )
        );
        return;
      }

      const nameParts = name.split(" ");

      const getPaymentMethodId = () => {
        if (cardType === "Amex") {
          return 4;
        } else if (cardType === "Visa") {
          return 1;
        } else if (cardType === "Mastercard") {
          return 2;
        } else {
          return 6;
        }
      };

      const getPaymentScheduleId = () => {
        switch (selectedSchedule) {
          case "3 Year Contract - Annual":
            return 1;
          case "3 Year Contract - Monthly":
            return 2;
          case "1 Year Contract - Annual":
            return 3;
          case "1 Year Contract - Monthly":
            return 4;
          default:
            return -1;
        }
      };

      const getDiscount = () => {
        var discount = 0;
        if (selectedSchedule.includes("3 Year")) discount += 5;
        if (couponWasApplied) discount += 20;
        return discount;
      };

      const customerData = {
        customer_first_name: nameParts[0],
        customer_last_name: nameParts.slice(1).join(" "),
        customer_phone: phone,
        customer_email: email,
        customer_company_name: companyName,
        special_instructions: specialInstructions,
        ...addressObj,
        ...billingAddressObj,
        surface: area,
        service_ids: [],
        selected_services: selectedServices,
        core_soil_visits: coreSoilVisits,
        customer_language_id: localStorage.getItem("lang") === "en" ? 1 : 2,
        payment_credit_number: cardNumber,
        payment_credit_name: cardHolder,
        payment_credit_expiration: cardExpiration.split("/").reverse().join(""),
        payment_credit_ccv: cardCVC,
        payment_schedule: getPaymentScheduleId(),
        payment_method_id: getPaymentMethodId(),
        discount: getDiscount(),
        purchase_price_before_tax: servicesCost,
        purchase_price_after_tax: servicesCost * 1.14975,
        call_requested: false,
      };

      await createCustomerAndContract(customerData);

      setContactWasSentToGHL(true);

      navigate("/success");
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong! Please try again.");
    }
  };

  const handleExpiryChange = (e) => {
    let value = e.target.value;
    value = value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    if (value.length > 2) {
      value = value.slice(0, 2) + "/" + value.slice(2);
    }
    setCardExpiration(value.slice(0, 5)); // Limit to MM/YY format
  };

  const handleCardNumberChange = (e) => {
    let value = e.target.value.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
    let maxLength = 16;

    if (/^3/.test(value)) {
      maxLength = 15;
      setCardType("Amex");
    } else if (/^4/.test(value)) {
      setCardType("Visa");
    } else if (/^5/.test(value)) {
      setCardType("Mastercard");
    } else {
      setCardType("");
    }

    value = value.slice(0, maxLength);
    value = value.replace(/(.{4})/g, "$1 ").trim();

    setCardNumber(value);
  };

  const handleRetrieve = async (result) => {
    const cities = await getCities();

    const { features } = result;
    const { geometry, properties } = features[0];
    const { coordinates } = geometry;
    const { context } = properties;
    const { full_address: address } = properties;
    const [long, lat] = coordinates;

    const addressCity = context.place.name;
    const cityObject = cities.find(
      (city) => city.city_name.toLowerCase() === addressCity.toLowerCase()
    );

    const billingObject = {
      customer_billing_address_number: context?.address?.address_number,
      customer_billing_address_name: context?.address?.street_name,
      customer_billing_city_id: cityObject ? cityObject?.city_id : null,
      customer_billing_province: context?.region?.name,
      customer_billing_postal_code: context?.postcode?.name,
      customer_billing_country: context?.country?.name,
      customer_billing_latitude: lat,
      customer_billing_longitude: long,
    };

    setBillingAddressObj(billingObject);
    setSelectedBillingAddress(address);
  };

  useDetectPageLeave(
    {
      firstName: name.split(" ")[0],
      lastName: name.split(" ").slice(1).join(" "),
      phone,
      address: selectedAddress,
      workflowName:
        localStorage.getItem("lang") === "en"
          ? "New Lead - ENG - 2024 - abandoned cart"
          : "New Lead - FR - 2024 - abandoned cart",
    },
    contactWasSentToGHL,
    setContactWasSentToGHL
  );

  useEffect(() => {
    setMaxStep((prev) => Math.max(prev, 4));
    setBillingAddressObj(addressObj);
    setSelectedBillingAddress(selectedAddress);
  }, [
    setStep,
    setMaxStep,
    setSelectedBillingAddress,
    setBillingAddressObj,
    selectedAddress,
    addressObj,
  ]);

  return (
    <>
      <ScrollToTop ref={outletRef} fullPage />
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}
        className={styles.checkoutPage}
        ref={outletRef}
        style={{
          width: "65rem",
        }}
      >
        <form className={styles.container}>
          <div className={styles.leftSide}>
            <label htmlFor="Full Name">{t("Full Name")}</label>
            <input
              type="text"
              placeholder={t("Full Name")}
              id="Full Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <label htmlFor="email">{t("Email")}</label>
            <input
              type="email"
              id="email"
              placeholder={t("Enter your email")}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <label htmlFor="phone">{t("Phone Number")}</label>
            <input
              type="text"
              id="phone"
              placeholder="+1 (123) 123 - 1234"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <label htmlFor="company name">{t("Company Name")}</label>
            <input
              type="text"
              id="company name"
              placeholder={t("Enter company name (optional)")}
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
            {!showSpecialInstructions && specialInstructions.length === 0 ? (
              <button
                className={styles.specialButton}
                style={{ marginBottom: "2.5313rem" }}
                onClick={() => setShowSpecialInstructions(true)}
              >
                {t("Add Special Instructions")}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <path
                    d="M14.25 9.75H9.75V14.25H8.25V9.75H3.75V8.25H8.25V3.75H9.75V8.25H14.25V9.75Z"
                    fill="#65AE6B"
                  />
                </svg>
              </button>
            ) : (
              <>
                <label htmlFor="special instructions">
                  {t("Add Special Instructions")}
                </label>
                <textarea
                  id="special instructions"
                  placeholder={t("Message to Us")}
                  className={styles.specialInput}
                  value={specialInstructions}
                  onChange={(e) => setSpecialInstructions(e.target.value)}
                />
              </>
            )}

            <label htmlFor="ccn">{t("Card Number")}</label>
            <div className={styles.inputWrapper}>
              <input
                id="ccn"
                type="tel"
                inputMode="numeric"
                pattern="[0-9\s]{13,19}"
                autoComplete="cc-number"
                maxLength="19"
                placeholder="1234 1234 1234 1234"
                className={styles.inputField}
                value={cardNumber}
                onChange={handleCardNumberChange}
              />
              <CardImages />
            </div>

            <label htmlFor="Cardholder Name">{t("Cardholder Name")}</label>
            <input
              type="text"
              placeholder="John Doe"
              id="Cardholder Name"
              value={cardHolder}
              onChange={(e) => setCardHolder(e.target.value)}
            />

            <div className={styles.cardInfo}>
              <div className={styles.cardInfo__item}>
                <label htmlFor="Expiry">{t("Expiry")}</label>
                <input
                  type="text"
                  placeholder="MM/YY"
                  id="Expiry"
                  value={cardExpiration}
                  onChange={handleExpiryChange}
                />
              </div>
              <div className={styles.cardInfo__item}>
                <label htmlFor="CVV">CVC</label>
                <input
                  type="text"
                  placeholder="000"
                  id="CVV"
                  maxLength={3}
                  value={cardCVC}
                  onChange={(e) => setCardCVC(e.target.value)}
                />
              </div>
            </div>

            <label htmlFor="billing address">{t("Billing Address")}</label>
            <div className={styles.searchBoxDiv}>
              <SearchBox
                accessToken={process.env.REACT_APP_MAPBOX_KEY}
                value={selectedBillingAddress}
                onChange={(newAddress) => setSelectedBillingAddress(newAddress)}
                onRetrieve={handleRetrieve}
                theme={searchBoxTheme}
                placeholder={t("Enter your address")}
                id="billing address"
              />
            </div>

            {!isMobile ? (
              <>
                <button className={styles.submitButton} onClick={handleSubmit}>
                  {t("Submit")}
                </button>
                <div
                  className={styles.formAlert}
                  style={{ marginBottom: "10px" }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M7.2 12H8.8V7.2H7.2V12ZM8 5.6C8.22667 5.6 8.4168 5.5232 8.5704 5.3696C8.724 5.216 8.80053 5.02613 8.8 4.8C8.8 4.57333 8.7232 4.3832 8.5696 4.2296C8.416 4.076 8.22613 3.99947 8 4C7.77333 4 7.5832 4.0768 7.4296 4.2304C7.276 4.384 7.19947 4.57387 7.2 4.8C7.2 5.02667 7.2768 5.2168 7.4304 5.3704C7.584 5.524 7.77387 5.60053 8 5.6ZM8 16C6.89333 16 5.85333 15.7899 4.88 15.3696C3.90667 14.9493 3.06 14.3795 2.34 13.66C1.62 12.94 1.05013 12.0933 0.6304 11.12C0.210667 10.1467 0.000533333 9.10667 0 8C0 6.89333 0.210133 5.85333 0.6304 4.88C1.05067 3.90667 1.62053 3.06 2.34 2.34C3.06 1.62 3.90667 1.05013 4.88 0.6304C5.85333 0.210667 6.89333 0.000533333 8 0C9.10667 0 10.1467 0.210133 11.12 0.6304C12.0933 1.05067 12.94 1.62053 13.66 2.34C14.38 3.06 14.9501 3.90667 15.3704 4.88C15.7907 5.85333 16.0005 6.89333 16 8C16 9.10667 15.7899 10.1467 15.3696 11.12C14.9493 12.0933 14.3795 12.94 13.66 13.66C12.94 14.38 12.0933 14.9501 11.12 15.3704C10.1467 15.7907 9.10667 16.0005 8 16ZM8 14.4C9.78667 14.4 11.3 13.78 12.54 12.54C13.78 11.3 14.4 9.78667 14.4 8C14.4 6.21333 13.78 4.7 12.54 3.46C11.3 2.22 9.78667 1.6 8 1.6C6.21333 1.6 4.7 2.22 3.46 3.46C2.22 4.7 1.6 6.21333 1.6 8C1.6 9.78667 2.22 11.3 3.46 12.54C4.7 13.78 6.21333 14.4 8 14.4Z"
                      fill="#65AE6B"
                    />
                  </svg>
                  <span>
                    {t(
                      "Payment information will be securely encrypted and added to your profile in our secured server for future payments"
                    )}
                  </span>
                </div>
                <div className={styles.formAlert}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M7.2 12H8.8V7.2H7.2V12ZM8 5.6C8.22667 5.6 8.4168 5.5232 8.5704 5.3696C8.724 5.216 8.80053 5.02613 8.8 4.8C8.8 4.57333 8.7232 4.3832 8.5696 4.2296C8.416 4.076 8.22613 3.99947 8 4C7.77333 4 7.5832 4.0768 7.4296 4.2304C7.276 4.384 7.19947 4.57387 7.2 4.8C7.2 5.02667 7.2768 5.2168 7.4304 5.3704C7.584 5.524 7.77387 5.60053 8 5.6ZM8 16C6.89333 16 5.85333 15.7899 4.88 15.3696C3.90667 14.9493 3.06 14.3795 2.34 13.66C1.62 12.94 1.05013 12.0933 0.6304 11.12C0.210667 10.1467 0.000533333 9.10667 0 8C0 6.89333 0.210133 5.85333 0.6304 4.88C1.05067 3.90667 1.62053 3.06 2.34 2.34C3.06 1.62 3.90667 1.05013 4.88 0.6304C5.85333 0.210667 6.89333 0.000533333 8 0C9.10667 0 10.1467 0.210133 11.12 0.6304C12.0933 1.05067 12.94 1.62053 13.66 2.34C14.38 3.06 14.9501 3.90667 15.3704 4.88C15.7907 5.85333 16.0005 6.89333 16 8C16 9.10667 15.7899 10.1467 15.3696 11.12C14.9493 12.0933 14.3795 12.94 13.66 13.66C12.94 14.38 12.0933 14.9501 11.12 15.3704C10.1467 15.7907 9.10667 16.0005 8 16ZM8 14.4C9.78667 14.4 11.3 13.78 12.54 12.54C13.78 11.3 14.4 9.78667 14.4 8C14.4 6.21333 13.78 4.7 12.54 3.46C11.3 2.22 9.78667 1.6 8 1.6C6.21333 1.6 4.7 2.22 3.46 3.46C2.22 4.7 1.6 6.21333 1.6 8C1.6 9.78667 2.22 11.3 3.46 12.54C4.7 13.78 6.21333 14.4 8 14.4Z"
                      fill="#65AE6B"
                    />
                  </svg>
                  <span
                    style={{
                      color: "#65ae6b",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => setIsModalOpen(true)}
                  >
                    {t(
                      "View our Privacy Policy and Terms of Service for more information"
                    )}
                  </span>
                </div>
              </>
            ) : (
              <div className={styles.greenDivider}></div>
            )}
          </div>
          <div className={styles.rightSide}>
            {!isMobile && (
              <div className={styles.rightSide__smallPill}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="17"
                  viewBox="0 0 12 17"
                  fill="none"
                >
                  <path
                    d="M5.78377 8.05343C5.26789 8.05343 4.77314 7.8485 4.40836 7.48371C4.04357 7.11893 3.83864 6.62418 3.83864 6.1083C3.83864 5.59242 4.04357 5.09766 4.40836 4.73288C4.77314 4.3681 5.26789 4.16317 5.78377 4.16317C6.29966 4.16317 6.79441 4.3681 7.15919 4.73288C7.52397 5.09766 7.72891 5.59242 7.72891 6.1083C7.72891 6.36374 7.67859 6.61667 7.58084 6.85267C7.48309 7.08866 7.33981 7.30309 7.15919 7.48371C6.97857 7.66434 6.76414 7.80761 6.52814 7.90537C6.29215 8.00312 6.03921 8.05343 5.78377 8.05343ZM5.78377 0.661926C4.33931 0.661926 2.954 1.23574 1.93261 2.25713C0.911215 3.27852 0.337402 4.66383 0.337402 6.1083C0.337402 10.1931 5.78377 16.223 5.78377 16.223C5.78377 16.223 11.2301 10.1931 11.2301 6.1083C11.2301 4.66383 10.6563 3.27852 9.63494 2.25713C8.61355 1.23574 7.22824 0.661926 5.78377 0.661926Z"
                    fill="#65AE6B"
                  />
                </svg>
                <span>{t("Address")}</span>
              </div>
            )}

            {!isMobile && (
              <div className={styles.rightSide__address}>{selectedAddress}</div>
            )}

            {!isMobile ? (
              <>
                <SelectedServices />
                <div
                  className={styles.rightSide__inputWrapper}
                  style={{ marginTop: "25px", marginBottom: "17px" }}
                >
                  <input
                    type="text"
                    placeholder={t("Enter Promo Code")}
                    id="promo_code"
                    value={promoCode}
                    onChange={(e) => setPromoCode(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        checkPromoCode(e);
                      }
                    }}
                    disabled={isPromoDisabled}
                  />
                  <button
                    className={styles.rightSide__submitBtn}
                    onClick={checkPromoCode}
                    disabled={isPromoDisabled}
                  >
                    {t("Submit")}
                  </button>
                </div>
                {couponWasApplied && (
                  <span className={styles.couponText}>
                    {t("20% discount was applied!")}
                  </span>
                )}
                <PaymentSchedule
                  price={servicesCost}
                  setSelectedSchedule={setSelectedSchedule}
                  selectedSchedule={selectedSchedule}
                />
              </>
            ) : (
              <>
                <PaymentSchedule
                  price={servicesCost}
                  setSelectedSchedule={setSelectedSchedule}
                  selectedSchedule={selectedSchedule}
                />
                <div
                  className={styles.rightSide__inputWrapper}
                  style={{ marginTop: "25px", marginBottom: "17px" }}
                >
                  <input
                    type="text"
                    placeholder={t("Enter Promo Code")}
                    id="promo_code"
                    value={promoCode}
                    onChange={(e) => setPromoCode(e.target.value)}
                    disabled={isPromoDisabled}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        checkPromoCode(e);
                      }
                    }}
                  />
                  <button
                    className={styles.rightSide__submitBtn}
                    onClick={checkPromoCode}
                    disabled={isPromoDisabled}
                  >
                    {t("Submit")}
                  </button>
                </div>
                {couponWasApplied && (
                  <span className={styles.couponText}>
                    {t("20% discount was applied!")}
                  </span>
                )}
                <SelectedServices />
              </>
            )}

            {isMobile && (
              <>
                <button
                  className={styles.submitButton}
                  onClick={handleSubmit}
                  style={{ marginTop: "24px" }}
                >
                  Submit
                </button>
                <div className={styles.formAlert}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M7.2 12H8.8V7.2H7.2V12ZM8 5.6C8.22667 5.6 8.4168 5.5232 8.5704 5.3696C8.724 5.216 8.80053 5.02613 8.8 4.8C8.8 4.57333 8.7232 4.3832 8.5696 4.2296C8.416 4.076 8.22613 3.99947 8 4C7.77333 4 7.5832 4.0768 7.4296 4.2304C7.276 4.384 7.19947 4.57387 7.2 4.8C7.2 5.02667 7.2768 5.2168 7.4304 5.3704C7.584 5.524 7.77387 5.60053 8 5.6ZM8 16C6.89333 16 5.85333 15.7899 4.88 15.3696C3.90667 14.9493 3.06 14.3795 2.34 13.66C1.62 12.94 1.05013 12.0933 0.6304 11.12C0.210667 10.1467 0.000533333 9.10667 0 8C0 6.89333 0.210133 5.85333 0.6304 4.88C1.05067 3.90667 1.62053 3.06 2.34 2.34C3.06 1.62 3.90667 1.05013 4.88 0.6304C5.85333 0.210667 6.89333 0.000533333 8 0C9.10667 0 10.1467 0.210133 11.12 0.6304C12.0933 1.05067 12.94 1.62053 13.66 2.34C14.38 3.06 14.9501 3.90667 15.3704 4.88C15.7907 5.85333 16.0005 6.89333 16 8C16 9.10667 15.7899 10.1467 15.3696 11.12C14.9493 12.0933 14.3795 12.94 13.66 13.66C12.94 14.38 12.0933 14.9501 11.12 15.3704C10.1467 15.7907 9.10667 16.0005 8 16ZM8 14.4C9.78667 14.4 11.3 13.78 12.54 12.54C13.78 11.3 14.4 9.78667 14.4 8C14.4 6.21333 13.78 4.7 12.54 3.46C11.3 2.22 9.78667 1.6 8 1.6C6.21333 1.6 4.7 2.22 3.46 3.46C2.22 4.7 1.6 6.21333 1.6 8C1.6 9.78667 2.22 11.3 3.46 12.54C4.7 13.78 6.21333 14.4 8 14.4Z"
                      fill="#65AE6B"
                    />
                  </svg>
                  <span>
                    {t(
                      "Payment information will be securely encrypted and added to your profile in our secured server for future payments"
                    )}
                  </span>
                </div>
                <div className={styles.formAlert}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M7.2 12H8.8V7.2H7.2V12ZM8 5.6C8.22667 5.6 8.4168 5.5232 8.5704 5.3696C8.724 5.216 8.80053 5.02613 8.8 4.8C8.8 4.57333 8.7232 4.3832 8.5696 4.2296C8.416 4.076 8.22613 3.99947 8 4C7.77333 4 7.5832 4.0768 7.4296 4.2304C7.276 4.384 7.19947 4.57387 7.2 4.8C7.2 5.02667 7.2768 5.2168 7.4304 5.3704C7.584 5.524 7.77387 5.60053 8 5.6ZM8 16C6.89333 16 5.85333 15.7899 4.88 15.3696C3.90667 14.9493 3.06 14.3795 2.34 13.66C1.62 12.94 1.05013 12.0933 0.6304 11.12C0.210667 10.1467 0.000533333 9.10667 0 8C0 6.89333 0.210133 5.85333 0.6304 4.88C1.05067 3.90667 1.62053 3.06 2.34 2.34C3.06 1.62 3.90667 1.05013 4.88 0.6304C5.85333 0.210667 6.89333 0.000533333 8 0C9.10667 0 10.1467 0.210133 11.12 0.6304C12.0933 1.05067 12.94 1.62053 13.66 2.34C14.38 3.06 14.9501 3.90667 15.3704 4.88C15.7907 5.85333 16.0005 6.89333 16 8C16 9.10667 15.7899 10.1467 15.3696 11.12C14.9493 12.0933 14.3795 12.94 13.66 13.66C12.94 14.38 12.0933 14.9501 11.12 15.3704C10.1467 15.7907 9.10667 16.0005 8 16ZM8 14.4C9.78667 14.4 11.3 13.78 12.54 12.54C13.78 11.3 14.4 9.78667 14.4 8C14.4 6.21333 13.78 4.7 12.54 3.46C11.3 2.22 9.78667 1.6 8 1.6C6.21333 1.6 4.7 2.22 3.46 3.46C2.22 4.7 1.6 6.21333 1.6 8C1.6 9.78667 2.22 11.3 3.46 12.54C4.7 13.78 6.21333 14.4 8 14.4Z"
                      fill="#65AE6B"
                    />
                  </svg>
                  <span
                    style={{
                      color: "#65ae6b",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => setIsModalOpen(true)}
                  >
                    {t(
                      "View our Privacy Policy and Terms of Service for more information"
                    )}
                  </span>
                </div>
              </>
            )}
          </div>
        </form>
        {!isMobile && (
          <button className={styles.backButton} onClick={handleBackClick}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
            >
              <path
                d="M10.5 3.39394L9.58483 2.5L4.5 7.5L9.58997 12.5L10.5 11.6061L6.32005 7.5L10.5 3.39394Z"
                fill="white"
              />
            </svg>
            {t("Back")}
          </button>
        )}

        {isMobile && <MenuBar pos="bottom" />}

        {isModalOpen && (
          <Modal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            showCloseButton={false}
          >
            <div className={styles.modalContainer}>
              <h3 className={styles.modalContainer__heading}>
                {t("Terms and Conditions")}
              </h3>
              <p>
                {t(
                  "The customer acknowledges that the company Vision Gazon is not responsible for breakage caused during visits, unless Vision Gazon has been informed in writing, by the customer (in the sales contract or by certified mail) of the existence of installations such as wires, pipes, irrigation, or other structures that may be damaged during operations performed by Vision Gazon technicians."
                )}
              </p>
              <p>
                {t(
                  "Customer Resolution Rights: You can resolve this contract, for any reason, for a period of 10 days after reception of the duplicate contract and the documents attached thereto. For the complete list of Rights, please refer to the Consumer Protection Act, section 58."
                )}
              </p>
              <p>
                {t(
                  "Applicable Laws and Jurisdiction of the Courts: This contract is governed by and construed in accordance with the laws of the Province of Quebec, without regard to conflict of law provisions applicable thereto. The parties hereby irrevocably and unconditionally agree to the jurisdiction of all courts of competent jurisdiction to sit on appeal of such courts of first instance."
                )}
              </p>
              <p>
                {t(
                  "In the event the customer desires to terminate the contract prior to the end of the service agreement, the customer must pay for all the services rendered and an early termination fee of 50$ (fifty dollars) plus all applicable taxes."
                )}
              </p>
              <p>
                {t(
                  "In accordance with Law 25, the personal information of the customer's profile, including their name, address, phone number, email address and credit card information is held by Vision Gazon for the duration of their contract plus an additional 12 months. Client information is not shared with any 3rd party."
                )}
              </p>
              <p>
                {t(
                  "* Deposit not refundable after ten days following the signature of the sales contract."
                )}
              </p>
            </div>
          </Modal>
        )}
      </motion.div>
    </>
  );
};

export default CheckoutPage;
