import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { Toaster } from "react-hot-toast";
import { Outlet, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AppDataProvider } from "./contexts/AppData.context";
import { useAutoAnimate } from "@formkit/auto-animate/react";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Stepper from "./components/Stepper";
import PromoBanner from "./components/PromoBanner";

import "./App.scss";

function App() {
  const [searchParams] = useSearchParams();
  const langFromUrl = searchParams.get("lang");
  const [lang, setLang] = useState(
    langFromUrl || localStorage.getItem("lang") || "fr"
  );
  const [isPromoBannerVisible, setIsPromoBannerVisible] = useState(true);
  const [animationParent] = useAutoAnimate();

  const { i18n } = useTranslation();

  const isMobile = useMediaQuery({ query: "(max-width: 1010px)" });

  const onLanguageChange = (newLang) => {
    setLang(newLang);
  };

  useEffect(() => {
    i18n.changeLanguage(lang);
    localStorage.setItem("lang", lang);
  }, [lang, i18n]);

  return (
    <main ref={animationParent}>
      <AppDataProvider>
        {isPromoBannerVisible && (
          <PromoBanner setIsPromoBannerVisible={setIsPromoBannerVisible} />
        )}
        <Header onLanguageChange={onLanguageChange} lang={lang} />
        <div className="outlet">
          {!isMobile && <Stepper />}
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Outlet />
          </div>
        </div>
        {!isMobile && <Footer />}
        <Toaster position={isMobile ? "top-center" : "bottom-center"} />
      </AppDataProvider>
    </main>
  );
}

export default App;
