import React, { useState, useContext } from "react";
import { AppDataContext } from "../contexts/AppData.context";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import LOGO from "../assets/logo.svg";
import GLOBE from "../assets/globe-icon.png";

import styles from "../styles/Header.module.scss";
import GRASS_ICON from "../assets/nav-grass.png";
const Header = ({ onLanguageChange, lang }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const { t } = useTranslation();
  const { isMobile } = useContext(AppDataContext);

  const navigate = useNavigate();

  return (
    <header className={styles.header}>
      {isMobile ? (
        <>
          <img
            className={styles.logoImage}
            src={LOGO}
            alt="Vision Gazon's Logo"
            onClick={() => (window.location.href = "https://visiongazon.com/")}
          />
          <button
            className={styles.hamburger}
            onClick={() => setSidebarOpen(!sidebarOpen)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="14"
              viewBox="0 0 24 14"
              fill="none"
            >
              <rect width="24" height="2" fill="#65AE6B" />
              <rect y="6" width="24" height="2" fill="#65AE6B" />
              <rect y="12" width="24" height="2" fill="#65AE6B" />
            </svg>
          </button>
          {sidebarOpen && (
            <div className={styles.sidebar}>
              <div className={styles.sidebar__closeButtonContainer}>
                <button
                  className={styles.sidebar__closeButton}
                  onClick={() => setSidebarOpen(false)}
                >
                  Close
                </button>
              </div>

              <div className={styles.sidebar__separator}></div>

              <nav className={styles.navigation}>
                <a href="https://visiongazon.com/">
                  <span>{t("Home")}</span>
                </a>

                <a href="https://visiongazon.com/services">
                  <span>{t("Services")}</span>
                </a>

                <a href="https://visiongazon.com/about-us">
                  <span>{t("About")}</span>
                </a>

                <a href="https://visiongazon.com/contact">
                  <span>{t("Contact")}</span>
                </a>
              </nav>

              <div className={styles.sidebar__separator}></div>

              <div className={styles.languageSwitcher}>
                <img
                  src={GLOBE}
                  alt="globe icon"
                  className={styles.languageSwitcher__globeIcon}
                />
                <button
                  className={
                    lang === "fr"
                      ? `${styles.languageButton} ${styles["languageButton--selected"]}`
                      : styles.languageButton
                  }
                  onClick={() => onLanguageChange("fr")}
                >
                  {t("French")}
                </button>
                <span className={styles.languageSwitcher__separator}></span>
                <button
                  className={
                    lang === "en"
                      ? `${styles.languageButton} ${styles["languageButton--selected"]}`
                      : styles.languageButton
                  }
                  onClick={() => onLanguageChange("en")}
                >
                  {t("English")}
                </button>
              </div>
              <button
                className={styles.rightSide__cta}
                onClick={() => navigate("/")}
              >
                {t("Online Quote")}
              </button>
            </div>
          )}
        </>
      ) : (
        <>
          <img
            className={styles.logoImage}
            src={LOGO}
            alt="Vision Gazon's Logo"
            onClick={() => (window.location.href = "https://visiongazon.com/")}
          />
          <nav className={styles.navigation}>
            <img src={GRASS_ICON} alt="dot" className={styles.grass} />
            <a href="https://visiongazon.com/services">
              <span>{t("Services")}</span>
            </a>

            <img src={GRASS_ICON} alt="dot" className={styles.grass} />
            <a href="https://visiongazon.com/about-us">
              <span>{t("About")}</span>
            </a>

            <img src={GRASS_ICON} alt="dot" className={styles.grass} />
            <a href="https://visiongazon.com/contact">
              <span>{t("Contact")}</span>
            </a>
          </nav>
          <div className={styles.rightSide}>
            <div className={styles.languageSwitcher}>
              <img
                src={GLOBE}
                alt="globe icon"
                className={styles.languageSwitcher__globeIcon}
              />
              <button
                className={
                  lang === "fr"
                    ? `${styles.languageButton} ${styles["languageButton--selected"]}`
                    : styles.languageButton
                }
                onClick={() => onLanguageChange("fr")}
              >
                {t("French")}
              </button>
              <span className={styles.languageSwitcher__separator}></span>
              <button
                className={
                  lang === "en"
                    ? `${styles.languageButton} ${styles["languageButton--selected"]}`
                    : styles.languageButton
                }
                onClick={() => onLanguageChange("en")}
              >
                {t("English")}
              </button>
            </div>
            <span className={styles.rightSide__separator}></span>
            <button
              className={styles.rightSide__cta}
              onClick={() => navigate("/")}
            >
              {t("Online Quote")}
            </button>
          </div>
        </>
      )}
    </header>
  );
};
export default Header;
