import React, { useContext, useEffect } from "react";
import area from "@turf/area";
import { AppDataContext } from "../contexts/AppData.context";
import { useTranslation } from "react-i18next";
import { FaLocationDot } from "react-icons/fa6";

import styles from "../styles/AreaPanel.module.scss";

import CALCULATE_ICON from "../assets/square-off-outline.svg";

const AreaPanel = ({ polygons, manualAreaEntry, mode, updateLawnSize }) => {
  const {
    setArea,
    area: calculatedArea,
    selectedAddress,
    isMobile,
  } = useContext(AppDataContext);
  const { t } = useTranslation();

  useEffect(() => {
    if (!manualAreaEntry) {
      // Only calculate area automatically if area was not manually entered
      let polygonArea = 0;
      for (const polygon of polygons) {
        polygonArea += area(polygon);
      }

      setArea((polygonArea * 10.764).toFixed(2));
      updateLawnSize((polygonArea * 10.764).toFixed(2));
    }
  }, [polygons, manualAreaEntry, setArea, updateLawnSize]);

  return (
    <div className={styles.lawnArea}>
      {mode === "map" ? (
        <div
          className={styles.areaValue}
          style={{ maxWidth: isMobile ? "80%" : "100%" }}
        >
          <FaLocationDot />
          <p>{t(`${selectedAddress}`)}</p>
        </div>
      ) : (
        <div
          className={styles.areaValue}
          style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }}
        >
          <img src={CALCULATE_ICON} alt="Calculate" height="20rem" />
          {t(`YOUR SFT: `)}
          <span>
            {Number(calculatedArea).toLocaleString()} {t("SFT")}
          </span>
        </div>
      )}
    </div>
  );
};

export default AreaPanel;
