import { useState, useRef, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AppDataContext } from "../contexts/AppData.context";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import ScrollToTop from "../components/ScrollToTop";
import Map, { Marker } from "react-map-gl";
import toast from "react-hot-toast";
import useDetectPageLeave from "../hooks/useDetectPageLeave";
import AreaPanel from "../components/AreaPanel";
import Modal from "../components/Modal";
import TraceLawn from "../components/TraceLawn";
import styles from "../styles/AddressPage.module.scss";
import serviceItemStyles from "../styles/ServiceItem.module.scss";
import MenuBar from "../components/MenuBar";
import "mapbox-gl/dist/mapbox-gl.css";
import "maplibre-gl/dist/maplibre-gl.css";

import { createCustomerAndContract, sendContactToGHL } from "../middleware/api";

const lawnSizes = [
  "0 - 1999",
  "2000 - 3999",
  "4000 - 5999",
  "6000 - 7999",
  "8000 - 9,999",
  "10,000 - 12,499",
  "12,500 - 14,999",
  "15,000 - 19,999",
  "20,000 - 24,999",
  "25,000 - 29,999",
  "30,000+",
];

const SqftPage = () => {
  const [features] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddressOutOfRange] = useState(false);
  const [isReceivePhoneCallClicked, setIsReceivePhoneCallClicked] =
    useState(false);
  const [manualAreaEntry, setManualAreaEntry] = useState(true);
  const [tooLateModalOpen, setTooLateModalOpen] = useState(false);
  const [isTraceLawnOpen, setIsTraceLawnOpen] = useState(false);
  const [selectedLawnSize, setSelectedLawnSize] = useState("");
  const [userHasSelectedLawnSize, setUserHasSelectedLawnSize] = useState(false);
  const { i18n } = useTranslation();

  const {
    long,
    lat,
    area,
    setArea,
    setStep,
    selectedAddress,
    name,
    phone,
    isMobile,
    addressObj,
    contactWasSentToGHL,
    setContactWasSentToGHL,
    setMaxStep,
  } = useContext(AppDataContext);

  const [viewState, setViewState] = useState({
    latitude: lat,
    longitude: long,
    zoom: 16,
  });

  const navigate = useNavigate();

  const mapRef = useRef(null);
  const outletRef = useRef(null);
  const { t } = useTranslation();

  const bottomSheetRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (bottomSheetRef.current) {
        const { scrollTop } = document.documentElement;
        if (scrollTop > 0) {
          bottomSheetRef.current?.classList.add(styles.expanded);
        } else {
          bottomSheetRef.current?.classList.remove(styles.expanded);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setStep(2);
    setMaxStep((prev) => Math.max(prev, 2));
  }, [setStep, setMaxStep]);

  const areButtonsDisabled =
    selectedAddress === "" ||
    name === "" ||
    phone === "" ||
    isAddressOutOfRange;

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsReceivePhoneCallClicked(false);
  };

  const handleGetOnlineQuote = async () => {
    if (areButtonsDisabled) {
      toast.error(
        t(
          "Please fill in all fields and make sure your address is within our service area."
        )
      );
    } else if (area === 0 && !userHasSelectedLawnSize) {
      toast.error(t("Please select your lawn area on the map."));
    } else if (isDateOutOfRange()) {
      setTooLateModalOpen(true);
      await handleReceivePhoneCall();
    } else if (area >= 30000) {
      setIsModalOpen(true);
    } else {
      setStep((prev) => prev + 1);
      navigate("/services");
    }
  };

  const handleReceivePhoneCall = async () => {
    if (areButtonsDisabled) {
      toast.error(
        t(
          "Please fill in all fields and make sure your address is within our service area."
        )
      );
    } else if (area === 0) {
      toast.error(t("Please select your lawn area on the map."));
    } else {
      try {
        const addressObjValues = Object.values(addressObj);

        if (addressObjValues.includes(null)) {
          toast.error(
            t(
              "There was a problem with your address. Please select it correctly from the dropdown."
            )
          );
          return;
        }

        const nameParts = name.split(" ");

        const customerData = {
          customer_first_name: nameParts[0],
          customer_last_name: nameParts.slice(1).join(" "),
          customer_phone: phone,
          ...addressObj,
          surface: area,
          service_ids: [],
          discount: 0,
          customer_language_id: localStorage.getItem("lang") === "en" ? 1 : 2,
          call_requested: true,
        };

        await createCustomerAndContract(customerData);

        if (!contactWasSentToGHL) {
          await sendContactToGHL({
            firstName: nameParts[0],
            lastName: nameParts.slice(1).join(" "),
            phone: phone,
            address: selectedAddress,
            workflowName:
              localStorage.getItem("lang") === "en"
                ? "New Lead - ENG - 2024 - receive phone call"
                : "New Lead - FR - 2024 - receive phone call",
          });

          setContactWasSentToGHL(true);
        }

        if (!isDateOutOfRange()) {
          setIsReceivePhoneCallClicked(true);
          setIsModalOpen(true);
        } else {
          setTooLateModalOpen(true);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const isDateOutOfRange = () => {
    const today = new Date();
    const start = new Date(today.getFullYear(), 6, 15); // July 15 of current year
    const end = new Date(today.getFullYear() + 1, 0, 1); // January 1 of next year

    return today >= start && today < end;
  };

  const toggleTraceLawn = () => {
    setIsTraceLawnOpen((prev) => !prev);
  };

  const determineLawnSize = (area) => {
    if (area >= 0 && area <= 1999) return "0 - 1999";
    if (area >= 2000 && area <= 3999) return "2000 - 3999";
    if (area >= 4000 && area <= 5999) return "4000 - 5999";
    if (area >= 6000 && area <= 7999) return "6000 - 7999";
    if (area >= 8000 && area <= 9999) return "8000 - 9,999";
    if (area >= 10000 && area <= 12499) return "10,000 - 12,499";
    if (area >= 12500 && area <= 14999) return "12,500 - 14,999";
    if (area >= 15000 && area <= 19999) return "15,000 - 19,999";
    if (area >= 20000 && area <= 24999) return "20,000 - 24,999";
    if (area >= 25000 && area <= 29999) return "25,000 - 29,999";
    if (area >= 30000) return "30,000+";
    return "";
  };

  const handleAreaChange = (e) => {
    const newArea = Number.parseFloat(e.target.value);
    setManualAreaEntry(true);
    setArea(newArea);
    const size = determineLawnSize(newArea);
    setSelectedLawnSize(size);
  };

  const handleRadioChange = (size) => {
    setManualAreaEntry(true);
    setUserHasSelectedLawnSize(true);
    setSelectedLawnSize(size);
    if (size === "30,000+") {
      setArea(30000);
    } else {
      const areaValue = Number.parseInt(size.split(" ")[2].replace(",", ""));
      setArea(areaValue);
    }
  };

  const updateLawnSize = (newArea) => {
    const size = determineLawnSize(newArea);
    setSelectedLawnSize(size);
  };
  useDetectPageLeave(
    {
      firstName: name.split(" ")[0],
      lastName: name.split(" ").slice(1).join(" "),
      phone: phone,
      address: selectedAddress,
      workflowName:
        localStorage.getItem("lang") === "en"
          ? "New Lead - ENG - 2024 - abandoned cart"
          : "New Lead - FR - 2024 - abandoned cart",
    },
    contactWasSentToGHL,
    setContactWasSentToGHL
  );
  return (
    <>
      <ScrollToTop ref={outletRef} />
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}
        className={styles.addressPage}
        style={{
          flexDirection: "column",
          width: "85%",
          position: isTraceLawnOpen ? "relative" : "static",
        }}
        ref={outletRef}
      >
        {!isTraceLawnOpen && (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "2rem",
                justifyContent: "space-between",
              }}
            >
              {!isMobile && (
                <section className={styles.infoSection}>
                  <div className={styles.infoSection__header}>
                    <div className={styles.freeQuote}>{t("FREE QUOTE")}</div>
                    <h1 style={{ textTransform: "capitalize" }}>
                      {t("Select Your Lawn's Sft")}
                    </h1>
                  </div>
                  <div
                    className={styles.form__areaLabel}
                    style={{ fontSize: 16 }}
                  >
                    {t("Enter your lawn's total area (sqft)")}
                  </div>
                  <input
                    type="number"
                    id="area"
                    placeholder="0.00"
                    value={area || `${area}`}
                    className={styles.number_input}
                    onChange={handleAreaChange}
                  />
                  <div
                    className={styles.form__areaLabel}
                    style={{ fontSize: 16 }}
                  >
                    {t("Or select from the list:")}
                  </div>
                  <div className={styles.form__checkBoxArea}>
                    {lawnSizes.map((size) => (
                      <div className={styles.form__checkbox} key={size}>
                        <input
                          type="radio"
                          name="lawnSize"
                          className={serviceItemStyles.inputGroup__radio}
                          checked={selectedLawnSize === size}
                          onChange={() => handleRadioChange(size)}
                        />
                        <span className={styles.form__checkboxText}>
                          {size} {t("sft")}
                        </span>
                      </div>
                    ))}
                  </div>
                </section>
              )}

              {!isMobile && (
                <section className={styles.mapSection}>
                  {!isMobile && (
                    <AreaPanel
                      polygons={Object.values(features)}
                      manualAreaEntry={manualAreaEntry}
                      mode="map"
                    />
                  )}
                  <div className={styles.mapContainer}>
                    <Map
                      {...viewState}
                      mapStyle="mapbox://styles/mapbox/satellite-v9"
                      mapboxAccessToken={process.env.REACT_APP_MAPBOX_KEY}
                      ref={mapRef}
                      onMove={(evt) => setViewState(evt.viewState)}
                      height="100%"
                      style={{ borderRadius: "12px" }}
                      interactive={false}
                    >
                      {long !== 0 && lat !== 0 && (
                        <Marker longitude={long} latitude={lat} color="red" />
                      )}{" "}
                    </Map>
                  </div>
                </section>
              )}
            </div>
            {!isMobile && (
              <div className={styles.buttons}>
                <button
                  className={styles.buttons__btn}
                  onClick={handleGetOnlineQuote}
                >
                  {t("Get an Online Quote")}
                </button>
                <span className={styles.buttons__separator}>{t("Or")}</span>

                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span
                    style={{
                      color: "#1E1E1E",
                      fontSize: i18n.language === "fr" ? "1rem" : "1.5rem",
                      fontWeight: 600,
                    }}
                  >
                    {t("Don't Know Your Lawn Sft?")}
                  </span>
                  <span style={{ fontSize: "0.875rem", color: "#094C36" }}>
                    {t("Trace your Lawn to find out your sft")}
                  </span>
                </div>
                <button
                  className={`${styles.buttons__btn} ${styles["buttons__btn--other"]}`}
                  onClick={toggleTraceLawn}
                >
                  {t("Trace Lawn")}
                </button>
              </div>
            )}
          </>
        )}

        {isModalOpen && (
          <Modal
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            showCloseButton={false}
          >
            <div className={styles.modalContainer}>
              <div className={styles.freeQuote}>
                {!isReceivePhoneCallClicked
                  ? t("Custom Quote")
                  : t("Thank you for completing the form")}
              </div>
              <div className={styles.modalHeading}>{t("Hang tight!")}</div>
              <div className={styles.modalText}>
                {!isReceivePhoneCallClicked
                  ? t(
                      "Your information was successfully submitted. Due to the large size of your lawn, we will be contacting you by phone in order to provide you with accurate pricing."
                    )
                  : t(
                      "Your information was successfully submitted. We will be contacting you very soon!"
                    )}
              </div>
              <div className={styles.modalButtons}>
                {area >= 30000 && !isReceivePhoneCallClicked && (
                  <button
                    className={styles.buttons__btn}
                    onClick={handleReceivePhoneCall}
                  >
                    {t("Request a call")}
                  </button>
                )}
                <button
                  className={styles.buttons__btn}
                  onClick={() =>
                    (window.location.href = "https://visiongazon.com/services")
                  }
                >
                  {t("Back to our services page")}
                </button>
              </div>
            </div>
          </Modal>
        )}

        {tooLateModalOpen && (
          <Modal
            isOpen={tooLateModalOpen}
            onClose={() => setTooLateModalOpen(false)}
            showCloseButton={false}
          >
            <div className={styles.modalContainer}>
              <div
                className={styles.modalText}
                style={{ maxWidth: "80%", marginBottom: 0 }}
              >
                <p>
                  {t(
                    "Unfortunately, our online quote system is not available from July 15th to January 1st."
                  )}
                </p>
                <p>
                  {t(
                    "We will be in contact with you in order to provide you a personalized quote!"
                  )}
                </p>
              </div>
            </div>
          </Modal>
        )}

        {isTraceLawnOpen && (
          <TraceLawn
            handleGetOnlineQuote={handleGetOnlineQuote}
            setIsTraceLawn={setIsTraceLawnOpen}
            setUserHasSelectedLawnSize={setUserHasSelectedLawnSize}
            updateLawnSize={updateLawnSize}
          />
        )}

        {isMobile && (
          <>
            <section className={styles.mapSection}>
              <div className={styles.mapContainer}>
                <Map
                  {...viewState}
                  mapStyle="mapbox://styles/mapbox/satellite-v9"
                  mapboxAccessToken={process.env.REACT_APP_MAPBOX_KEY}
                  ref={mapRef}
                  onMove={(evt) => setViewState(evt.viewState)}
                  height="100%"
                  style={{ borderRadius: "12px" }}
                  interactive={false}
                >
                  <AreaPanel
                    polygons={Object.values(features)}
                    manualAreaEntry={manualAreaEntry}
                    mode="map"
                  />
                  {long !== 0 && lat !== 0 && (
                    <Marker longitude={long} latitude={lat} color="red" />
                  )}
                </Map>
              </div>
            </section>

            <div className={styles.bottomSheet} ref={bottomSheetRef}>
              <div className={styles.bottomSheet__handle} />
              <section className={styles.infoSection}>
                <div className={styles.infoSection__header}>
                  <div className={styles.freeQuote}>{t("FREE QUOTE")}</div>
                  <h1 style={{ textTransform: "capitalize" }}>
                    {t("Select your lawn's sft")}
                  </h1>
                </div>

                <div className={styles.form__areaLabel}>
                  {t("Enter your lawn's total area (sqft)")}
                </div>
                <input
                  type="number"
                  id="area"
                  placeholder="0.00"
                  value={area || `${area}`}
                  className={styles.number_input}
                  onChange={handleAreaChange}
                />

                <div className={styles.form__areaLabel}>
                  {t("Or select from the list:")}
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: "0.5rem",
                    marginBottom: "1.5rem",
                    padding: "0 0.5rem",
                  }}
                >
                  {lawnSizes.map((size) => (
                    <div
                      className={styles.form__checkbox}
                      style={{
                        paddingTop: "0.15rem",
                        paddingBottom: "0.15rem",
                      }}
                      key={size}
                    >
                      <input
                        type="radio"
                        name="lawnSize"
                        className={serviceItemStyles.inputGroup__radio}
                        checked={selectedLawnSize === size}
                        onChange={() => handleRadioChange(size)}
                      />
                      <span className={styles.form__checkboxText}>{size}</span>
                    </div>
                  ))}
                </div>
                <MenuBar>
                  <div className={styles.buttons}>
                    <button
                      className={styles.buttons__btn}
                      onClick={handleGetOnlineQuote}
                    >
                      {t("Get an Online Quote")}
                    </button>
                  </div>
                </MenuBar>
              </section>
            </div>
          </>
        )}
      </motion.div>
    </>
  );
};

export default SqftPage;
